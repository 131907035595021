document.addEventListener('findstrLoaded', () => {
  const findstr = window.findstr || {};
  /**
   * Disable URL management for the home page search bar and popup search windows.
   */
  findstr.hooks.addFilter(
    'urlManagementDisabled',
    'findstr-com',
    (disabled, group) => {
      if ('main_search' !== group) {
        return true;
      }
      return disabled;
    }
  );

  findstr.hooks.addFilter(
    'findstrEmptyResultsMessageOnInit',
    'findstr-com',
    function (setEmptyAlertOnInit, group) {
      if ('main-search' === group) {
        setEmptyAlertOnInit = false;
      }

      return setEmptyAlertOnInit;
    }
  );
});

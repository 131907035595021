import domReady from '@wordpress/dom-ready';

domReady(() => {
  /* TRANSITION ARROW 2 */
  const btnsArrow2 = document.querySelectorAll(
    '.wp-block-button__link, .btn, .btn-secondary, .block-link a'
  );

  if (btnsArrow2.length > 0) {
    btnsArrow2.forEach((button) => {
      const btnText = button.textContent;
      const btnInnerContent = document.createElement('span');
      btnInnerContent.classList.add('btn__text');
      btnInnerContent.innerHTML =
        '<span class="text-main">' +
        btnText +
        '</span><span class="text-hover" aria-hidden="true">' +
        btnText +
        '</span>';

      // Clear existing content and append the new content
      button.innerHTML = '';
      button.appendChild(btnInnerContent);
    });
  }
});

import $ from 'jquery';

let fieldsAreInitialized = false;

function changeFileUpload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      fileUpload.querySelector('.gform_drop_instructions').textContent =
        wp.i18n._x(
          'Drag and drop file to upload',
          'drag and drop instruction',
          'vtx'
        );
    });
  }
}

$(document).on('gform_post_render', function () {
  fieldsAreInitialized = true;
  changeFileUpload();
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
  }
});

(function () {
  function changeFormLabel(label, input) {
    if ('' !== input.val()) {
      $(label).addClass('active');
    }

    $(label).on('click', function () {
      $(input).focus();
    });

    $(input)
      .on('focus', function () {
        $(label).addClass('active');
      })
      .on('focusout', function () {
        const value = $.trim($(this).val());
        if (value.length === 0 || $(this).val() === '(___) ___-____') {
          $(label).removeClass('active');
        }
      });
  }

  function addFormEvents() {
    $('.gform_wrapper').on('submit', function () {
      this.classList.add('is-loading');
    });

    $('.gfield').each(function () {
      const $field = $(this);

      if ($field.hasClass('initialized')) {
        return;
      }

      let $label;
      let $input;

      $field.addClass('initialized');

      $label = $field.find('.gfield_label');
      $input = $field.find(
        '.ginput_container input , .ginput_container textarea'
      );
      changeFormLabel($label, $input);

      if ($field.hasClass('gfield--has-multiple-inputs')) {
        $field.find('.ginput_complex > span').each(function () {
          const $deeperField = $(this);

          if ($deeperField.hasClass('initialized')) {
            return;
          }

          $deeperField.addClass('initialized');

          $label = $deeperField.find('label');
          $input = $deeperField.find('input, textarea');
          changeFormLabel($label, $input);
        });
      }
    });
  }

  function checkIfFormNeedsFocus($form) {
    if ($form.length && $form.find('.validation_error').length) {
      setTimeout(
        function () {
          this.find('.gfield_error input, .gfield_error textarea')
            .first()
            .focus();
        }.bind($form),
        100
      );
    }
  }

  function cleanClasses() {
    $('.gform_wrapper').removeClass('is-loading');
  }
  $(document).on('gform_post_render', function (event, formId) {
    fieldsAreInitialized = true;
    cleanClasses();
    addFormEvents();
    checkIfFormNeedsFocus($('#gform_wrapper_' + formId));
  });

  // ON READY
  $(function () {
    if (!fieldsAreInitialized) {
      // Initialize fields events only if they were not already.
      addFormEvents();
    }
  });

  function woocommerceFormLabel() {
    const wooField = document.querySelectorAll('.woocommerce-form-row');

    wooField.forEach(function (field) {
      const wooInput = field.querySelector('input');
      if ('' !== wooInput.value) {
        field.querySelector('label').classList.add('active');
      }
      wooInput.addEventListener('focus', function () {
        field.querySelector('label').classList.add('active');
        wooInput.addEventListener('focusout', function () {
          if ('' === wooInput.value) {
            field.querySelector('label').classList.remove('active');
          }
        });
      });
    });
  }

  woocommerceFormLabel();
})(jQuery);
